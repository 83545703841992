<template>
  <div class="login-container">
    <el-container>
      <el-main class="main-container">
        <el-card class="login-card" shadow="never" :body-style="cardCss">
          <h2> 後台登入</h2>
          <div class="login-content">
            <el-input
              autocomplete="off"
              placeholder="請輸入帳號"
              v-model="formData.username"
              @keyup.enter.native="handleLogin"
            />
            <el-input
              autocomplete="off"
              placeholder="請輸入密碼"
              show-password
              v-model="formData.password"
              @keyup.enter.native="handleLogin"
            />
          </div>
          <el-button type="primary" @click="handleLogin"> 登錄 </el-button>
          <div class="tipText">
            ※忘记密码可在群里找工作人员处理
            <!-- <br /> -->
            <!-- <span style="color: #0022ff">cs@dragonsborn.net </span><br />重新发行密码 -->
          </div>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formData: {
          username: '',
          password: '',
        },
        asd: 'asd',
      };
    },
    computed: {
      cardCss: {
        get() {
          return {
            display: 'flex',
            'flex-direction': 'column',
            gap: '15px',
          };
        },
      },
    },
    methods: {
      handleLogin() {
        this.$store.commit('setLoading', true);
        this.$store
          .dispatch('admin/login', {
            account_id: this.formData.username,
            password: this.formData.password,
          })
          .then((res) => {
            this.$MSG.success('登入成功');
            this.$router.replace({ name: 'ConsoleLayout' });
          })
          .catch(this.$autoCatch)
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ebeef5;
    .main-container {
      display: flex;
      justify-content: center;
      .login-card {
        max-width: 400px;
        width: 100%;
        h2 {
          font-size: 16px;
          color: #4f4f4f;
          text-align: center;
        }
        .login-content {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        .tipText {
          color: #ff0000;
          font-size: 14px;
          text-align: center;
          line-height: 20px;
        }
      }
    }
  }
  .el-button--primary:focus {
    outline-color: #66b1ff;
  }
</style>
